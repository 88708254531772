import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import filters from '@filters/filters';
import { isIOS } from '@utils/utils';
import { AppointmentListBookItem, AppointmentListBookItem1 } from './components';
import { getBookListAPI, getBookRecordAPI } from '@api/appointment';
import jsQR from 'jsqr';
export default {
  name: 'AppointmentList',
  mixins: [common, filters, scrollList, getPageList],
  components: {
    AppointmentListBookItem,
    AppointmentListBookItem1
  },
  data() {
    return {
      headerTitle: '',
      tabValue: 0,
      initParams: {
        // 初始化参数
        page: 1,
        size: 20
      },
      searchKey: {},
      // 搜索参数
      bookerPhone0: '',
      bookerPhone1: '',
      listData0: [],
      listData1: [],
      listData: [],
      scanPopupShow: false,
      scanValue: ''
    };
  },
  props: {
    status: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    ...mapGetters('user', ['projectInfo']),
    phoneType() {
      return isIOS();
    }
  },
  methods: {
    // 进入详情
    goDetail(data) {
      let {
        id
      } = data;
      this.$router.push({
        name: 'AppointmentDetail',
        query: {
          id
        }
      });
    },
    handleTabChange(index) {
      this.tabValue = index;
      if (index === 0) {
        this.bookerPhone0 = '';
        this.listData0 = [];
        this.listData = [];
        this.$store.dispatch('appointment/SetAppointmentList', []);
      } else {
        this.bookerPhone1 = '';
        this.listData1 = [];
        this.listData = [];
        this.$store.dispatch('appointment/SetAppointmentList', []);
        this.searchKey.page = 1;
        this.getListData();
      }
    },
    onSearch() {
      this.searchKey.page = 1;
      this.getListData({
        isPullDown: true
      });
    },
    onInput0(e) {
      if (e === '') {
        this.onClear0();
      }
    },
    onClear0() {
      this.listData0 = [];
      this.listData = this.listData0;
    },
    onInput1(e) {
      if (e === '') {
        this.onClear1();
      }
    },
    onClear1() {
      this.searchKey.page = 1;
      this.getListData();
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      if (this.tabValue === 0) {
        if (this.bookerPhone0 === '') {
          this.$toast('请输入手机号');
          return;
        }
      }
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          page,
          size
        } = this.searchKey;
        let {
          projectId
        } = this.projectInfo;
        let response;
        if (this.tabValue === 0) {
          response = await getBookListAPI({
            projectId,
            bookerPhone: this.bookerPhone0
          });
        } else {
          response = await getBookRecordAPI({
            pageNum: page,
            pageSize: size,
            bookerPhone: this.bookerPhone1
          });
        }
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let hasMore = false;
          if (this.tabValue === 0) {
            let resultData = result.data || [];
            resultData.forEach(i => i.status = 0);
            hasMore = false;
            this.listData = resultData;
            this.listData0 = this.listData;
            this.$store.dispatch('appointment/SetAppointmentList', this.listData0);
          } else {
            let resultData = result.data || [];
            resultData.forEach(i => i.status = 1);
            hasMore = page < result.pages;
            if (!isPullDown) {
              this.listData.push(...resultData);
            } else {
              this.listData = resultData;
            }
            this.listData1 = this.listData;
            this.$store.dispatch('appointment/SetAppointmentList', this.listData1);
          }
          if (!hasMore) {
            this.isLastPage = true;
          }
          await this.handleCallback({
            isError: false,
            hasMore,
            noDataMessage: this.tabValue === 0 ? '未找到该手机号预约记录' : undefined
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.resetParams();
      await this.getListData({
        isPullDown: true
      });
    },
    goApply() {
      this.$router.push({
        name: 'AppointmentApply'
      });
    },
    goScanTwo() {
      if (this.phoneType === 'ios') {
        // this.$toast('ios')
        window.webkit.messageHandlers.scanQRCode.postMessage('ios');
      } else {
        // this.$toast('android')
        if (window.commonJS) {
          window.commonJS.scanQRCode();
        }
      }
    },
    // goScan() {
    //   this.scanPopupShow = true;
    //   this.$nextTick(()=>{
    //     let video = document.createElement("video");
    //     this.video = video;
    //     if (!this.canvas) {
    //       let canvasElement = document.getElementById("scanCanvas");
    //       this.canvas = canvasElement.getContext("2d");
    //     }
    //     navigator.mediaDevices.getUserMedia({
    //       video: { facingMode: "environment" }
    //     }).then(stream=>{
    //       video.srcObject = stream;
    //       video.setAttribute("playsinline", true);
    //       video.play();
    //       requestAnimationFrame(this.scanTick);
    //     })
    //   })
    // },
    // scanTick() {
    //   let video = this.video;
    //   let canvas = this.canvas;
    //   if (video && video.readyState === video.HAVE_ENOUGH_DATA) {
    //
    //
    //     let canvasElement = document.getElementById("scanCanvas");
    //     canvasElement.height = video.videoHeight;
    //     canvasElement.width = video.videoWidth;
    //
    //     let wrapperEl = document.querySelector('.scanMain');
    //     let outWidth = wrapperEl.offsetWidth;
    //     let outHeight = canvasElement.height/canvasElement.width * outWidth;
    //     let scaleX = 1;
    //     let scaleY = 1;
    //     scaleY = outHeight/canvasElement.height;
    //     scaleX = outWidth/canvasElement.width;
    //     canvasElement.style.transform = 'scale('+scaleX+','+scaleY+')';
    //
    //     canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
    //     let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    //     let code = jsQR(imageData.data, imageData.width, imageData.height, {
    //       inversionAttempts: "dontInvert",
    //     });
    //     const drawLine = (begin, end, color) => {
    //       canvas.beginPath();
    //       canvas.moveTo(begin.x, begin.y);
    //       canvas.lineTo(end.x, end.y);
    //       canvas.lineWidth = 4;
    //       canvas.strokeStyle = color;
    //       canvas.stroke();
    //     }
    //     if (code) {
    //       drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
    //       drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
    //       drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
    //       drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
    //       setTimeout(()=>{
    //         this.onScanResult(code.data);
    //         console.log(code.data)
    //       }, 500)
    //       return
    //     }
    //   }
    //   requestAnimationFrame(this.scanTick);
    // },
    onCloseScan() {
      if (this.video) {
        let tracks = this.video.srcObject.getTracks();
        tracks.forEach(i => i.stop());
        this.video.pause();
        this.video.src = '';
        this.video = null;
      }
      this.scanPopupShow = false;
    },
    onScanResult(val) {
      // this.onCloseScan();
      this.bookerPhone0 = val;
      this.onSearch();
    }
  },
  filters: {},
  watch: {
    initParams: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
          this.searchKey = JSON.parse(JSON.stringify(newVal));
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    const {
      token,
      projectId,
      projectName,
      userId,
      userName
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId,
        projectName
      });
    }
  },
  mounted() {
    window.onScanResult = this.onScanResult;
  },
  activated() {
    if (this.tabValue === 0 && this.deactivatedCount) {
      this.getListData();
    }
  },
  deactivated() {
    this.deactivatedCount = 1;
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};