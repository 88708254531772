import filters from '@filters/filters';
import { dateFilter } from '@utils/utils';
import { sexMap, statusMap } from '@/dictionaries/appointment';
export default {
  name: 'AppointmentListBookItem',
  mixins: [filters],
  components: {},
  data() {
    return {
      headerExtraField: {
        isShow: true,
        title: '预计到访时间：',
        key: 'bookVisitTime',
        filterMethod: dateFilter
      },
      footerContentField: {
        isShow: true,
        title: '接待任务'
      },
      footerExtraField: {
        isShow: true,
        title: '',
        key: '',
        filterMethod: data => '去接待',
        getColorMethod: data => 'primary'
      },
      bodyFields: [{
        title: '姓名：',
        key: 'bookerName'
      }, {
        title: '性别：',
        key: 'bookerSex'
      }, {
        title: '手机号：',
        key: 'bookerPhone'
      }, {
        title: '来源渠道：',
        key: 'source'
      }]
    };
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    goDetail() {
      this.$emit('go-detail', this.detailInfo);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};