var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-appointment-navbar"
        }, [_c('van-tabs', {
          on: {
            "change": _vm.handleTabChange
          },
          model: {
            value: _vm.tabValue,
            callback: function ($$v) {
              _vm.tabValue = $$v;
            },
            expression: "tabValue"
          }
        }, [_c('van-tab', {
          attrs: {
            "title": "来访登记"
          }
        }, [_c('van-search', {
          attrs: {
            "placeholder": "输入客户手机号",
            "shape": "round",
            "clear-trigger": "always",
            "show-action": ""
          },
          on: {
            "input": _vm.onInput0,
            "clear": _vm.onClear0,
            "search": _vm.onSearch
          },
          scopedSlots: _vm._u([{
            key: "action",
            fn: function () {
              return [_c('div', {
                on: {
                  "click": _vm.onSearch
                }
              }, [_vm._v("搜索")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.bookerPhone0,
            callback: function ($$v) {
              _vm.bookerPhone0 = $$v;
            },
            expression: "bookerPhone0"
          }
        })], 1), _c('van-tab', {
          attrs: {
            "title": "来访接待记录"
          }
        }, [_c('van-search', {
          attrs: {
            "placeholder": "输入手机号",
            "shape": "round",
            "clear-trigger": "always",
            "show-action": ""
          },
          on: {
            "input": _vm.onInput1,
            "clear": _vm.onClear1,
            "search": _vm.onSearch
          },
          scopedSlots: _vm._u([{
            key: "action",
            fn: function () {
              return [_c('div', {
                on: {
                  "click": _vm.onSearch
                }
              }, [_vm._v("搜索")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.bookerPhone1,
            callback: function ($$v) {
              _vm.bookerPhone1 = $$v;
            },
            expression: "bookerPhone1"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_vm.tabValue == 0 ? [_c('div', {
          staticClass: "cyui-scroller-panel",
          staticStyle: {
            "bottom": "80px",
            "height": "auto"
          }
        }, [_vm.listData.length ? _c('cyui-scroller', {
          ref: "scroller",
          staticClass: "wrapper",
          attrs: {
            "scrollbar": _vm.scrollbarConfig,
            "pullDownRefresh": _vm.pullDownRefreshConfig,
            "pullUpLoad": _vm.pullUpLoadConfig,
            "startY": parseInt(_vm.startY)
          },
          on: {
            "pullingDown": _vm.handlePullingDown
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-appointment-list"
              }, _vm._l(_vm.listData, function (item, index) {
                return _c('appointment-list-book-item', {
                  key: index,
                  attrs: {
                    "detail-info": item
                  },
                  on: {
                    "go-detail": function ($event) {
                      return _vm.goDetail(item);
                    }
                  }
                });
              }), 1)];
            },
            proxy: true
          }], null, false, 2299915361)
        }) : _vm._e(), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()], 1), _c('div', {
          staticClass: "cyui-appointment-actions"
        }, [_c('van-button', {
          staticStyle: {
            "margin-bottom": "20px"
          },
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.goApply
          }
        }, [_vm._v("新客户登记 ")]), _c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.goScanTwo
          }
        }, [_vm._v("扫码接待")])], 1)] : _vm._e(), _vm.tabValue == 1 ? [_c('div', {
          staticClass: "cyui-scroller-panel"
        }, [_vm.listData.length ? _c('cyui-scroller', {
          ref: "scroller",
          staticClass: "wrapper",
          attrs: {
            "scrollbar": _vm.scrollbarConfig,
            "pullDownRefresh": _vm.pullDownRefreshConfig,
            "pullUpLoad": _vm.pullUpLoadConfig,
            "startY": parseInt(_vm.startY)
          },
          on: {
            "pullingDown": _vm.handlePullingDown,
            "pullingUp": _vm.handlePullingUp
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-appointment-list"
              }, _vm._l(_vm.listData, function (item, index) {
                return _c('appointment-list-book-item1', {
                  key: index,
                  attrs: {
                    "detail-info": item
                  },
                  on: {
                    "go-detail": function ($event) {
                      return _vm.goDetail(item);
                    }
                  }
                });
              }), 1)];
            },
            proxy: true
          }], null, false, 2230025584)
        }) : _vm._e(), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()], 1)] : _vm._e()];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    style: {
      height: '100vh',
      width: '100vw'
    },
    attrs: {
      "get-container": "body"
    },
    model: {
      value: _vm.scanPopupShow,
      callback: function ($$v) {
        _vm.scanPopupShow = $$v;
      },
      expression: "scanPopupShow"
    }
  }, [_c('div', {
    staticClass: "scanPanel"
  }, [_c('div', {
    staticClass: "scanMain"
  }, [_c('canvas', {
    ref: "scanCanvas",
    attrs: {
      "id": "scanCanvas"
    }
  })]), _c('div', {
    staticClass: "closeBtn"
  }, [_c('van-button', {
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.onCloseScan
    }
  }, [_vm._v("取消")])], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };